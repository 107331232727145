import {IWidgetControllerConfig} from '@wix/native-components-infra/dist/es/src/types/types'
import {
  createUouBiMiddlewareWithBiParams,
  createUsersBiMiddlewareWithBiParams,
  isRtlLanguage,
  BiParams,
  EVENTS_APP_ID,
} from '@wix/wix-events-commons-statics'
import {ExperimentsBag} from '@wix/wix-experiments'
import {bindActionCreators, PreloadedState} from 'redux'
import {setBaseEnvironment, setFormFactor} from '../../commons/actions/environment'
import {updateSiteSettings} from '../../commons/actions/site-settings'
import {isEventCalendarPaginationEnabled} from '../../commons/selectors/experiments'
import {decodeInstance} from '../../commons/selectors/instance'
import {initSentry, ErrorMonitor} from '../../commons/services/error-monitor'
import {importResources} from '../../commons/services/import-18n-resources'
import {getMultilingualInitialState} from '../../commons/services/multilingual'
import {getQueryParams} from '../../commons/services/navigation'
import {isMembersEventsPageInstalled} from '../../commons/utils/members-api'
import {createReduxStore, subscribeToStateChanges} from '../../commons/utils/store'
import {getLanguage, getPageInfo, isEditor, isMobile, isSSR} from '../../commons/utils/wix-code-api'
import {
  addCalendarMonth,
  closeMonthlyCalendarEvent,
  closeMonthlyCalendarPopup,
  calculateCalendarLayout,
  openMonthlyCalendarEvent,
  openMonthlyCalendarPopup,
  resetCalendar,
  subCalendarMonth,
} from '../actions/calendar-layout'
import {updateComponent, updateComponentDraft, updateStyleParams} from '../actions/component'
import {createEvent, shareEvent} from '../actions/event'
import {loadEventsPage} from '../actions/events'
import {closeListLayoutItems, openListLayoutItem, widgetLoaded} from '../actions/layout'
import {addLoginListener, loadMembersForEvents, openMembersModal} from '../actions/members'
import {navigateToPage, onLinkNavigatedToPage} from '../actions/navigate-to-page'
import {resized} from '../actions/resize'
import {updateSettings, updateStyle} from '../actions/sdk'
import * as eventsUou from '../bi/uou-bi-events-map'
import * as eventsUsers from '../bi/users-bi-events-map'
import {datesMiddleware} from '../middlewares/date'
import reducers from '../reducers'
import {sortEvents} from '../reducers/events'
import {isPaidPlansInstalled} from '../services/apps'
import {createWidgetFedopsLogger} from '../services/fedops'
import {Actions, State, StoreExtraArgs} from '../types/state'
import {userEventsLogger} from '../user-events-logger'
import {Api} from '../utils/api'
import {watchInstance} from '../../commons/actions/instance'
import {isResponsiveEditor} from '../../commons/selectors/environment'
import {UserRole} from '../../details-page/constants/constants'

const DSN = 'https://46ad76cef1c840139844ab4624c33fe2@sentry.wixpress.com/291'

export const createWidgetController = async (controller: IWidgetControllerConfig, experiments: ExperimentsBag) => {
  const monitor = initSentry(controller, DSN)
  return Promise.resolve({
    pageReady: monitor.withErrorBoundary(() => pageReady(controller, monitor, experiments)),
  })
}

const pageReady = async (controller: IWidgetControllerConfig, monitor: ErrorMonitor, experiments: ExperimentsBag) => {
  const fedopsLogger = createWidgetFedopsLogger(controller)

  const serverApi = new Api(controller)
  const {staticsBaseUrl} = controller.appParams.baseUrls
  const language = getLanguage(controller.wixCodeApi)
  const [translations, pageInfo, pageUrl, store] = await Promise.all([
    importResources(['demo-data', 'accessibility', 'widget'], language, staticsBaseUrl),
    getPageInfo(controller.wixCodeApi),
    controller.wixCodeApi.site.getSectionUrl({
      sectionId: 'events',
      appDefinitionId: EVENTS_APP_ID,
    }),
    createStore(controller, serverApi, experiments),
  ])
  const state = store.getState()

  const props = {
    pageUrl,
    baseUrl: controller.wixCodeApi.location.baseUrl,
    queryParams: getQueryParams(controller.wixCodeApi),
    pageInfo,
    state,
    translations,
    actions: exportedActions({
      store,
      appLoaded: fedopsLogger.onAppLoaded,
      monitor,
    }),
    isRTL: isRtlLanguage(language),
  }

  if (state.membersEnabled) {
    serverApi.onLogin(() => {
      store.dispatch(loadMembersForEvents() as any)
    })
  }

  controller.setProps(props)

  if (isSSR(controller.wixCodeApi)) {
    fedopsLogger.onSSRPageReady()
  }
}

const createBiMiddleware = (biParams: BiParams) => [
  createUsersBiMiddlewareWithBiParams(biParams, eventsUsers),
  createUouBiMiddlewareWithBiParams(biParams, eventsUou),
]

const createStore = async (controller: IWidgetControllerConfig, serverApi: Api, experiments: ExperimentsBag) => {
  const {wixCodeApi, platformAPIs, appParams, compId, config} = controller
  const initialData = await getInitialData(serverApi, controller, experiments)

  const biMiddleware = createBiMiddleware({
    wixCodeApi,
    platformAPIs,
    appParams,
    compId,
    user: {
      aid: initialData.instance.aid,
      uid: initialData.instance.uid,
    },
  })

  const userEventsLoggerMiddleware = userEventsLogger({wixCodeApi})

  const store = createReduxStore<State, StoreExtraArgs>({
    reducers,
    initialData,
    extraArguments: {
      serverApi,
      wixCodeApi,
      compId,
    },
    middleware: [...biMiddleware, userEventsLoggerMiddleware, datesMiddleware],
  })

  store.dispatch(updateStyleParams(config.style.styleParams))
  store.dispatch(setBaseEnvironment() as any)
  store.dispatch(addLoginListener() as any)

  store.dispatch(calculateCalendarLayout() as any)

  watchInstance(controller, store.dispatch)
  subscribeToStateChanges(controller, store)

  return store
}

const getInitialData = async (
  serverApi: Api,
  {appParams, wixCodeApi, config: controllerConfig}: IWidgetControllerConfig,
  experiments: ExperimentsBag,
): Promise<PreloadedState<State>> => {
  const [membersEnabled, paidPlansInstalled] = await Promise.all([
    isMembersEventsPageInstalled(wixCodeApi),
    isPaidPlansInstalled(wixCodeApi),
  ])

  const instance = appParams.instance
  const decodedInstance = decodeInstance(instance)
  const isTemplate = decodedInstance.siteIsTemplate

  const paginated = !isTemplate
  const paginatedCalendar = isEventCalendarPaginationEnabled(experiments)
  const showcase = isTemplate || isEditor(wixCodeApi) || isMobile(wixCodeApi)

  let widgetType, listLayout
  if (paginated) {
    const numbers = controllerConfig.style.styleParams.numbers
    widgetType = numbers.widgetType
    listLayout = numbers.listLayout
  }

  const {
    component: {events = [], config, id},
    siteSettings,
    demoEvents,
    hasMoreEvents,
    dates,
  } = await serverApi.getAppData(
    membersEnabled,
    paidPlansInstalled,
    isResponsiveEditor(controllerConfig),
    paginated,
    paginatedCalendar,
    showcase,
    widgetType,
    listLayout,
  )

  return {
    experiments,
    events: {
      events: sortEvents(events),
      hasMore: hasMoreEvents,
      moreLoading: false,
    },
    siteSettings,
    demoEvents,
    multilingual: getMultilingualInitialState(wixCodeApi),
    component: {
      ...config,
      id,
    },
    membersEnabled,
    paidPlansEnabled: paidPlansInstalled,
    memberLoggedIn: wixCodeApi.user.currentUser.loggedIn,
    owner: wixCodeApi.user.currentUser.role === UserRole.ADMIN,
    instance: {
      instance,
      ...decodedInstance,
    },
    calendarLayout: {
      referenceDate: dates.common?.referenceDate ?? null,
      loadedPages: dates.common?.referenceDate ? [dates.common.referenceDate] : [],
    },
    dates,
  } as PreloadedState<State>
}

const exportedActions = ({store, appLoaded, monitor}) => {
  const dispatchActions = {
    addCalendarMonth,
    subCalendarMonth,
    resetCalendar,
    calculateCalendarLayout,
    createEvent,
    updateSiteSettings,
    updateComponent,
    updateComponentDraft,
    navigateToPage,
    onLinkNavigatedToPage,
    updateStyle,
    updateStyleParams,
    updateSettings,
    setBaseEnvironment,
    openListLayoutItem,
    closeListLayoutItems,
    openMonthlyCalendarPopup,
    closeMonthlyCalendarPopup,
    openMonthlyCalendarEvent,
    closeMonthlyCalendarEvent,
    widgetLoaded,
    openMembersModal,
    shareEvent,
    resized,
    setFormFactor,
    loadEventsPage,
  }

  const actions: Actions = {
    ...bindActionCreators(dispatchActions, store.dispatch),
    appLoaded,
  }

  return monitor.bindActions(actions)
}
