import {IUser, IWidgetControllerConfig} from '@wix/native-components-infra/dist/es/src/types/types'
import {addQueryParams, stringifyParams, LIST_LAYOUT, WIDGET_TYPE} from '@wix/wix-events-commons-statics'
import {UPDATE_SITE_SETTINGS} from '../../commons/actions/site-settings'
import {SiteSettingsSettings} from '../../commons/types/state'
import {Api as BaseApi} from '../../commons/utils/api'
import {UPDATE_COMPONENT, UPDATE_COMPONENT_DRAFT} from '../actions/component'
import {CREATE_EVENT} from '../actions/event'
import {LOAD_ALL_EVENTS, LOAD_CALENDAR_EVENTS, LOAD_EVENTS} from '../actions/events'
import {LOAD_MEMBERS_FOR_EVENTS, PROMPT_LOGIN} from '../actions/members'
import StatusFilter = wix.events.editor.StatusFilter

export class Api extends BaseApi {
  constructor(controller: IWidgetControllerConfig) {
    super(controller)

    this.registrar = {
      [UPDATE_COMPONENT.NAME]: this.updateComponent,
      [UPDATE_COMPONENT_DRAFT.NAME]: this.updateComponentDraft,
      [UPDATE_SITE_SETTINGS.NAME]: this.updateSiteSettings,
      [CREATE_EVENT.NAME]: this.createEvent,
      [PROMPT_LOGIN.NAME]: this.promptLogin,
      [LOAD_MEMBERS_FOR_EVENTS.NAME]: this.getGuestLists,
      [LOAD_EVENTS.NAME]: this.loadEvents,
      [LOAD_ALL_EVENTS.NAME]: this.loadAllEvents,
      [LOAD_CALENDAR_EVENTS.NAME]: this.loadCalendarEvents,
    }
  }

  getAppData = (
    members: boolean,
    paidPlans: boolean,
    responsive = false,
    paginated = false,
    paginatedCalendar = false,
    showcase = false,
    widgetType?: WIDGET_TYPE,
    listLayout?: LIST_LAYOUT,
  ) => {
    const queryParams = {
      compId: this.compId,
      locale: this.language,
      regional: this.locale,
      viewMode: this.viewMode,
      members,
      paidPlans,
      responsive,
      paginated,
      paginatedCalendar,
      widgetType,
      listLayout,
      showcase,
      paginatedManual: true,
      tz: this.tz,
    }

    return this.api.get(addQueryParams(`/html/v2/widget-data`, stringifyParams(queryParams)))
  }

  loadEvents = (args: {
    offset: number
    filter: StatusFilter
    byEventId: boolean
    members: boolean
    paidPlans: boolean
    locale: string
    categoryId: string
  }): Promise<{events: ExtendedEvent[]; hasMore: boolean}> =>
    this.api.get(
      addQueryParams(
        '/web/paginated-events/viewer',
        stringifyParams({
          ...args,
          draft: this.viewMode !== 'site',
          compId: this.compId,
        }),
      ),
    )

  loadCalendarEvents = (args: {
    referenceDate: string
    filter: StatusFilter
    byEventId: boolean
    members: boolean
    paidPlans: boolean
    locale: string
    categoryId: string
    showcase: boolean
  }): Promise<{events: ExtendedEvent[]}> =>
    this.api.get(
      addQueryParams(
        '/web/calendar-events/viewer',
        stringifyParams({
          ...args,
          draft: this.viewMode !== 'site',
          compId: this.compId,
          tz: this.tz,
        }),
      ),
    )

  loadAllEvents = (members = false, paidPlans = false, locale: string): Promise<{events: ExtendedEvent[]}> => {
    return this.api.get(
      `/web/all-events/viewer?compId=${this.compId}&members=${members}&paidPlans=${paidPlans}&draft=true&locale=${locale}`,
    )
  }

  updateSiteSettings = (settings: SiteSettingsSettings) => {
    return this.api.put(`/web/site-settings`, {settings})
  }

  updateComponentDraft = (
    type: string,
    component: wix.events.editor.WebComponentConfig,
    members = false,
    paidPlans = false,
  ) =>
    this.api.put(`/web/component/${this.compId}/draft?members=${members}&paidPlans=${paidPlans}&noEvents=true`, {
      component,
    })

  updateComponent = (component: wix.events.editor.WebComponentConfig) =>
    this.api.put(`/web/component/${this.compId}`, {component})

  createEvent = (eventData: wix.events.EventData, ADI: boolean): Promise<{event: wix.events.Event; ADI: boolean}> => {
    return this.api.post('/adi/events', eventData).then((event) => ({event, ADI}))
  }

  getGuestLists = (eventIds: string[]): Promise<GuestLists> =>
    this.api.get(`/web/members?eventIds=${JSON.stringify(eventIds)}`)

  promptLogin = (lang: string) => {
    return new Promise(async (resolve, reject) => {
      try {
        await this.controller.wixCodeApi.user.promptLogin({
          mode: 'login',
          lang,
        })
        resolve(true)
      } catch (e) {
        if (typeof e !== 'string') {
          reject(e)
          return
        }
        resolve(false)
      }
    })
  }

  onLogin = (handler: LoginHandler) => {
    this.controller.wixCodeApi.user.onLogin(handler)
  }
}

export type LoginHandler = (user: IUser) => void
